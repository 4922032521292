import React from 'react'

import {
  Page_page_content_InfoCardListRecord as InfoCardListType,
} from '@festi/common/api/datocms/types/Page'

import InfoCardList from './InfoCardList'
import InfoCardSlider from './InfoCardSlider'

export default function InfoList(props: InfoCardListType): JSX.Element {
  if (props.slider) {
    return <InfoCardSlider {...props} />
  } else {
    return <InfoCardList {...props} />
  }
}
