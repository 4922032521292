import React from 'react'

import dynamic from 'next/dynamic'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {
  Page_page_content_InfoCardListRecord_infoCards as InfoCardType,
  Page_page_content_InfoCardListRecord as InfoCardListType,
} from '@festi/common/api/datocms/types/Page'
import {H2, PLarge} from '@festi/common/components/typography'

import {BlockWrapper} from '../layout'

const CustomSlider = dynamic(import('../common/CustomSlider'), {ssr: false})

const InfoCard = dynamic(import('../common/InfoCard'), {ssr: false})


const SliderWrapper = styled.div`
  overflow: hidden;

  ${media.sm`
    padding-top: 60px;
  `}
`

export default function InfoCardSlider({
  title,
  description,
  infoCards,
  small = false,
  darkMode = false,
  border = false,
}: InfoCardListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <H2 withGutter darkMode={darkMode}>
          {title}
        </H2>
        <PLarge withGutter darkMode={darkMode}>
          {description}
        </PLarge>
        <SliderWrapper>
          <CustomSlider xl={4} lg={3} md={2} sm={1} darkMode={darkMode}>
            {infoCards?.map(
              ({id, title, icon, description, link}: InfoCardType) => (
                <InfoCard
                  key={id}
                  title={title}
                  icon={icon}
                  link={link}
                  description={description}
                  border={border}
                  darkMode={darkMode}
                  small={small}
                />
              ),
            )}
          </CustomSlider>
        </SliderWrapper>
      </Container>
    </BlockWrapper>
  )
}
