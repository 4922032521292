import React from 'react'

import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import styled from 'styled-components'

import {H3, P} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 10px 20px 40px;
  color: ${palette('white')};
  background-color: ${palette('lightBlue')};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`

const InfoImage = styled(Image)`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`

const testImage = {
  alt: null,
  aspectRatio: 1.1636363636363636,
  base64:
    'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgTDxQXDhAQDgsQDhYNFhEOFxUZGCIfFhUaHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLDg0OFQ0NHS8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIABUAGAMBIgACEQEDEQH/xAAbAAACAQUAAAAAAAAAAAAAAAAABgIBAwQFB//EAB0QAAICAwEBAQAAAAAAAAAAAAABAgMEBRFBITH/xAAWAQEBAQAAAAAAAAAAAAAAAAAEAwX/xAAWEQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhEDEQA/ANnhzgtdBd8JVTra/RVxdrN4sY98JvY2RXeh4srYdB1apX1yATcXb3KhtSATlo7BZx/lcS/e+VoAM2C9ZeA+0PpUAFYjr//Z',
  bgColor: '#c39e3b',
  height: 220,
  sizes: '(max-width: 256px) 100vw, 256px',
  src: 'https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&fit=crop&h=220&w=256',
  srcSet:
    'https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.25&fit=crop&h=220&w=256 64w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.5&fit=crop&h=220&w=256 128w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.75&fit=crop&h=220&w=256 192w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&fit=crop&h=220&w=256 256w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=1.5&fit=crop&h=220&w=256 384w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=2&fit=crop&h=220&w=256 512w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=3&fit=crop&h=220&w=256 768w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=4&fit=crop&h=220&w=256 1024w',
  title: null,
  webpSrcSet:
    'https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.25&fit=crop&fm=webp&h=220&w=256 64w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.5&fit=crop&fm=webp&h=220&w=256 128w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=0.75&fit=crop&fm=webp&h=220&w=256 192w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&fit=crop&fm=webp&h=220&w=256 256w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=1.5&fit=crop&fm=webp&h=220&w=256 384w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=2&fit=crop&fm=webp&h=220&w=256 512w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=3&fit=crop&fm=webp&h=220&w=256 768w,https://www.datocms-assets.com/40035/1712575994-elko_02.jpg?crop=focalpoint&dpr=4&fit=crop&fm=webp&h=220&w=256 1024w',
  width: 256,
  __typename: 'ResponsiveImage',
}

interface Props {
  title: string
  description: string
  link?: string
}

export default function NewInfoCard({title, description}: Props): JSX.Element {
  return (
    <Container>
      <Inner>
        <H3 style={{color: styledTheme.palette.white}} headline>
          {title}
        </H3>

        <P style={{color: styledTheme.palette.white}}>{description}</P>
      </Inner>

      <InfoImage data={testImage} />
    </Container>
  )
}
