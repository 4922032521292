import React from 'react'

import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {prop, ifProp, palette} from 'styled-tools'

import {
  Page_page_content_BulletListRecord_bullets as BulletType,
  Page_page_content_BulletListRecord as BulletListType,
} from '@festi/common/api/datocms/types/Page'
import {DynamicIcon} from '@festi/common/components/common'
import {H2, H3, P} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'

import {BlockWrapper} from '../layout'

interface TextImageProps {
  imgUrl: string
}

interface MirroredProps {
  mirrored: boolean
}

const TextImage = styled.div<TextImageProps>`
  ${fluidRange('min-height', '380px', '400px')}

  &:before {
    content: '';
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0;
    top: 0;
    background-image: url(${prop('imgUrl')});
    background-position: center;
    background-size: cover;
  }
`

const TextWrapper = styled.div`
  position: relative;
`

const Text = styled.div<MirroredProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0;
  padding-top: ${ifProp({mirrored: true}, '24px', '0px')};

  ${media.md`
    ${ifProp({mirrored: true}, 'padding-left: 40px', 'padding-right: 10px')};
    padding-top: 0;
  `};
`

const TextContainer = styled.div`
  max-width: 1344px;
  margin: auto;
`

const BulletsWrapper = styled.div`
  ${fluidRange('padding-top', '20px', '40px')};

  svg {
    color: ${palette('lightBlue')};
  }
`

const Bullet = styled.div`
  ${fluidRange('padding-right', '20px', '40px')};
  ${fluidRange('padding-bottom', '20px', '40px')};
  display: flex;
  flex-direction: row;

  ${media.md`
    flex-direction: column;
  `}
`

const IconWrapper = styled.div`
  padding-bottom: 20px;
  padding-right: 20px;
`

export default function BulletsBlock({
  title,
  image,
  bullets,
  mirrored,
  darkMode = false,
}: BulletListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <TextContainer>
          <TextWrapper>
            <Row>
              <Col col={12} md={7} order={mirrored ? 'last' : 'first'}>
                <Text mirrored={mirrored}>
                  <H2 darkMode={darkMode}>{title}</H2>
                  <BulletsWrapper>
                    <Row>
                      {bullets?.map(({id, icon, text, title}: BulletType) => (
                        <Col col={12} sm={6} key={id}>
                          <Bullet>
                            <IconWrapper>
                              <DynamicIcon
                                icon={icon}
                                size={24}
                              />
                            </IconWrapper>
                            <div>
                              <H3 size={5} darkMode={darkMode} withGutter>
                                {title}
                              </H3>
                              <P darkMode={darkMode}>{text}</P>
                            </div>
                          </Bullet>
                        </Col>
                      ))}
                    </Row>
                  </BulletsWrapper>
                </Text>
              </Col>
              <Col col={12} md={5}>
                <TextImage imgUrl={image?.responsiveImage.src} />
              </Col>
            </Row>
          </TextWrapper>
        </TextContainer>
      </Container>
    </BlockWrapper>
  )
}
