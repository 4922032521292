import {H1, P} from '@festi/common/components/typography'

import {TitleContentProps} from './interface'
import {TitleBlock} from './styles'

export function TitleContent({
  title,
  description,
  darkMode,
}: TitleContentProps): JSX.Element {
  return (
    <TitleBlock>
      <H1 size={2} withGutter darkMode={darkMode}>
        {title}
      </H1>
      {!!description && (
        <P withGutter darkMode={darkMode}>
          {description}
        </P>
      )}
    </TitleBlock>
  )
}
