import React from 'react'

import {useMedia} from 'react-use'
import styled, {css} from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'
import ToolsLineIcon from 'remixicon-react/ToolsLineIcon'

import {H4} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card} from '@festi/common/api/datocms/types/Page'

import BlogCard from '../cards/redesign/BlogCard'
import BrandCard from '../cards/redesign/BrandCard'
import AdviceCard from '../cards/redesign/AdviceCard'
import NewInfoCard from '../cards/redesign/NewInfoCard'
import CategoryCard from '../cards/redesign/CategoryCard'
import NewImageCard from '../cards/redesign/NewImageCard'
import SingleProductCard from '../cards/redesign/SingleProductCard'
import PromoCard from '../cards/redesign/PromoCard'
import LargeImageCard from '../cards/redesign/LargeImageCard'

const cardStyle = css`
  border-radius: 12px;
  background-color: white;
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '10px')};

  ${media.sm`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.md`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'item-1 item-2 item-3 item-4'
      'item-5 item-6 item-7 item-8';
  `}
`

export const GridItem = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  ${cardStyle}
`

const EmptyCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export type Content = Page_page_content_GridSystemRecord_card & {
  id: string
  slug: string | null
}

type EntryType = Content['__typename']

type CardMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EntryType]?: any
}

const cardMap: CardMap = {
  ProductRecord: SingleProductCard,
  InfoCardRecord: NewInfoCard,
  ImageCardRecord: NewImageCard,
  BlogCardRecord: BlogCard,
  CategoryRecord: CategoryCard,
  BrandCardRecord: BrandCard,
  AdviceCardRecord: AdviceCard,
  PromoCardRecord: PromoCard,
  LargeImageCardRecord: LargeImageCard,
}

export type GridAreaNames =
  | '1big'
  | '8small'
  | '1half4small'
  | '4small1half'
  | '4small2horizontal'
  | '1half2horizontal'

interface GridItemProps {
  quantity: number
  mobile: string
  desktop: string
}
type GridTemplate = {
  [key in GridAreaNames]: GridItemProps
}

const gridTemplates: GridTemplate = {
  '1big': {
    quantity: 1,
    mobile: `
    'item-1 item-1 item-1 item-1'
    'item-1 item-1 item-1 item-1'
  `,
    desktop: `
      'item-1 item-1 item-1 item-1'
      'item-1 item-1 item-1 item-1'
    `,
  },
  '8small': {
    quantity: 8,
    desktop: `
      'item-1 item-2 item-3 item-4'
      'item-5 item-6 item-7 item-8'
    `,
    mobile: `
      'item-1 item-2'
      'item-3 item-4'
      'item-5 item-6'
      'item-7 item-8'
    `,
  },
  '1half4small': {
    quantity: 5,
    mobile: `
      'item-1 item-1'
      'item-1 item-1'
      'item-2 item-3'
      'item-4 item-5'
    `,
    desktop: `
      'item-1 item-1 item-2 item-3'
      'item-1 item-1 item-4 item-5'
    `,
  },
  '4small1half': {
    quantity: 5,
    mobile: `
      'item-1 item-2'
      'item-3 item-4'
      'item-5 item-5'
      'item-5 item-5'
    `,
    desktop: `
      'item-1 item-2 item-5 item-5'
      'item-3 item-4 item-5 item-5'
    `,
  },
  '4small2horizontal': {
    quantity: 6,
    mobile: `
      'item-1 item-2'
      'item-3 item-4'
      'item-5 item-5'
      'item-6 item-6'
    `,
    desktop: `
      'item-1 item-2 item-3 item-4'
      'item-5 item-5 item-6 item-6'
    `,
  },
  '1half2horizontal': {
    quantity: 3,
    mobile: `
      'item-1 item-1'
      'item-1 item-1'
      'item-2 item-2'
      'item-3 item-3'
    `,
    desktop: `
      'item-1 item-1 item-2 item-2'
      'item-1 item-1 item-3 item-3'
    `,
  },
}

interface Props {
  cards: Page_page_content_GridSystemRecord_card[]
  heading?: string
  gridSelect: GridAreaNames
}

export default function Grid({cards, heading, gridSelect}: Props) {
  const isMobile = useMedia('(max-width: 1039px)', false)

  const selectedTemplate = gridTemplates[gridSelect]

  const gridTemplateAreas = isMobile
    ? selectedTemplate?.mobile
    : selectedTemplate?.desktop

  return (
    <Container>
      {heading && <H4 withGutter>{heading}</H4>}

      <GridContainer style={{gridTemplateAreas}}>
        {cards
          ?.slice(0, selectedTemplate?.quantity || 8)
          ?.map((card, index) => {
            const Component = cardMap?.[card?.__typename]
            return (
              <GridItem key={index} style={{gridArea: `item-${index + 1}`}}>
                {Component ? (
                  <Component {...card} />
                ) : (
                  <EmptyCard>
                    <ToolsLineIcon size={40} />
                    <span>WIP</span>
                  </EmptyCard>
                )}
              </GridItem>
            )
          })}
      </GridContainer>
    </Container>
  )
}
