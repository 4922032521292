import React from 'react'

import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'

import {
  Page_page_content_PageHeaderRecord as PageHeaderType,
} from '@festi/common/api/datocms/types/Page'
import {H2, PLarge} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'

interface PageHeaderImageProps {
  imgUrl: string
}

const PageHeaderImage = styled.div<PageHeaderImageProps>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  background-image: url(${prop('imgUrl')});
  background-position: center;
  background-size: cover;
  min-height: 270px;

  ${media.sm`
    background-image: none;
    min-height: 0px;
  `}

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 50%;
    background-image: url(${prop('imgUrl')});
    background-position: center;
    background-size: cover;

    ${media.sm`
      content: '';
    `}
  }
`

const PageHeaderWrapper = styled.div`
  position: relative;
  ${fluidRange('min-height', '300px', '400px')}
  background-color: ${palette('white')};
  box-shadow: -4px 4px 30px rgba(0, 14, 82, 0.05);
  padding-top: 20px;
`

const PageHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: none;
  max-width: 630px;

  ${media.sm`
    ${fluidRange('min-height', '300px', '400px')}
    padding-right: 10px;
  `}
`

export default function PageHeader({
  title,
  description,
  image,
}: PageHeaderType): JSX.Element {
  return (
    <PageHeaderWrapper>
      <Container>
        <Row>
          <Col col={12} sm={6}>
            <PageHeaderText>
              <H2 withGutter>{title}</H2>
              <PLarge withGutter>{description}</PLarge>
            </PageHeaderText>
          </Col>
        </Row>
        <PageHeaderImage imgUrl={image?.responsiveImage.src} />
      </Container>
    </PageHeaderWrapper>
  )
}
