import React from 'react'

import cn from 'classnames'
import {Image} from 'react-datocms'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {AlgoliaBanner_algoliaBanner as AlgoliaBannerType} from '@festi/common/api/datocms/types/AlgoliaBanner'
import {Page_page_content_PageBannerRecord as PageBannerType} from '@festi/common/api/datocms/types/Page'

interface LinkProps {
  children: React.ReactNode
}

interface BackgroundProps {
  background: string
}

const PageBannerWrapper = styled.div<BackgroundProps>`
  position: relative;
  background-color: ${palette('white')};
  box-shadow: -4px 4px 30px rgba(0, 14, 82, 0.05);
  background-image: url('${(props) => props.background}');
  background-size: cover;
`

const MobileBannerImage = styled.div`
  width: 100%;
  height: 100%;

  ${media.md`
    display: none;
  `}
`

const DesktopBannerImage = styled.div`
  width: 100%;
  height: 100%;

  > div {
    margin: auto;
  }

  &.mobileImage {
    display: none;

    ${media.md`
      display: block;
    `}
  }
`

export default function PageBanner({
  image,
  mobileImage,
  link,
}: PageBannerType | AlgoliaBannerType): JSX.Element {
  const BannerLink = ({children}: LinkProps) =>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    link ? (
      <a href={link} aria-label={image.responsiveImage?.alt || 'Banner link'}>
        {children}
      </a>
    ) : (
      <>{children}</>
    )

  return (
    <PageBannerWrapper background={image.responsiveImage.base64}>
      <BannerLink>
        {mobileImage && (
          <MobileBannerImage>
            <Image data={mobileImage.responsiveImage} />
          </MobileBannerImage>
        )}
        <DesktopBannerImage className={cn({mobileImage})}>
          <Image data={image.responsiveImage} />
        </DesktopBannerImage>
      </BannerLink>
    </PageBannerWrapper>
  )
}
