import React from 'react'

import {Image} from 'react-datocms'
import styled from 'styled-components'

import {H3} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_BrandCardRecord_logo} from '@festi/common/api/datocms/types/Page'

const Container = styled.div`
  position: relative;
  height: 100%;
`

const Inner = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${fluidRange('padding', '30px', '60px')};
  
`
const LogoImage = styled(Image)`
  max-height: 40px;
`

interface Props {
  name: string
  link: string
  logo: Page_page_content_GridSystemRecord_card_BrandCardRecord_logo | null
}

export default function BrandCard({name, link, logo}: Props): JSX.Element {

  return (
    <Container>
      <Inner href={link || '#'}>
        <LogoImage
          data={logo.responsiveImage}
          layout='responsive'
          objectFit='contain' />
      </Inner>
    </Container>
  )
}