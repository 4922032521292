import {CardWrapper} from 'src/components/cards/product'
import {MiniSlider} from 'src/components/common/CustomSlider'
import {darkModeCSS, spacing} from 'src/components/layout/BlockWrapper'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const SliderBlockWrapper = styled.div`
  position: relative;

  &.darkMode {
    ${darkModeCSS}
  }

  ${MiniSlider} {
    ${CardWrapper}:hover {
      // Send sliding indicator back because of ProductCard overflow
      z-index: -1;
    }
  }
`

export const SliderWrapper = styled.div`
  ${spacing(10, 30)}

  ${media.md`
    overflow: hidden;
  `}
`

export const ListItemWrapper = styled.div`
  height: 100%;
  padding-bottom: 16px;
`

export const TitleBlock = styled.div`
  max-width: 800px;
`
