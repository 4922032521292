import React from 'react'

import parse from 'html-react-parser'
import {Container} from 'styled-bootstrap-grid'

import {Page_page_content_HtmlBlockRecord as HtmlType} from '@festi/common/api/datocms/types/Page'

import {BlockWrapper} from '../layout'

export default function HtmlBlock({html}: HtmlType): JSX.Element {
  const parsedHtml = parse(html)
  return (
    <BlockWrapper>
      {!!parsedHtml && <Container>{parsedHtml}</Container>}
    </BlockWrapper>
  )
}
