import React from 'react'

import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {prop, ifProp, palette} from 'styled-tools'

import {Page_page_content_IconCardImageBlockRecord as IconCardImageType} from '@festi/common/api/datocms/types/Page'
import {fluidRange} from '@festi/common/utils/styles'

import {TextCard} from '../cards'
import {BlockWrapper} from '../layout'

interface ImageProps {
  imgUrl: string
  mirrored?: boolean
  smHide?: boolean
}

interface MirroredProps {
  mirrored?: boolean
}

const Image = styled.div<ImageProps>`
  ${fluidRange('min-height', '380px', '500px')}

  &:before {
    content: '';
    position: absolute;
    left: ${ifProp({mirrored: true}, '0px', '10%')};
    right: ${ifProp({mirrored: true}, '10%', '0px')};
    bottom: 0;
    top: 0;
    background-image: url(${prop('imgUrl')});
    background-position: center;
    background-size: cover;
    border-color: ${palette('lightBlue')};

    ${media.sm`
      left: ${ifProp({mirrored: true}, '0px', '-100px')};
      right: ${ifProp({mirrored: true}, '-100px', '0px')};
    `}

    ${media.sm`
      display: ${ifProp({smHide: true}, 'none', 'block')};
    `}
  }
`

const TextCardWrapper = styled.div<MirroredProps>`
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-right: ${ifProp({mirrored: true}, '0px', '5%')};
  margin-left: ${ifProp({mirrored: true}, '5%', '0px')};

  ${media.sm`
    height: 100%;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  `}
`

const CardImageWrapper = styled.div`
  position: relative;
  ${fluidRange('min-height', '380px', '500px')};
`

const borderLeftCSS = css`
  border-left: 4px solid ${palette('lightBlue')};
`

const borderRightCSS = css`
  border-right: 4px solid ${palette('lightBlue')};
`

const Border = styled.div<MirroredProps>`
  border-color: ${palette('lightBlue')};
  ${ifProp({mirrored: true}, borderLeftCSS, borderRightCSS)};
  z-index: 1;
`

export default function IconCardImageBlock({
  title,
  content,
  image,
  icon,
  mirrored = false,
}: IconCardImageType): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <CardImageWrapper>
          <Row>
            <Col
              col={12}
              sm={6}
              order="last"
              smOrder={mirrored ? 'last' : 'first'}
            >
              <TextCardWrapper>
                <Border mirrored={mirrored}>
                  <TextCard title={title} content={content} icon={icon} />
                </Border>
              </TextCardWrapper>
            </Col>
            <Col col={12} sm={6}>
              <Image imgUrl={image?.responsiveImage.src} mirrored={mirrored} />
            </Col>
          </Row>
        </CardImageWrapper>
      </Container>
    </BlockWrapper>
  )
}
