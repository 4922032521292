import React from 'react'

import styled from 'styled-components'

interface VideoBlockProps {
  title: string
  provider: string
  providerUid: string
}

const IFrameWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const VideoBlockContainer = styled.div`
  margin-bottom: 40px;
`

export default function Video({
  title,
  provider,
  providerUid,
}: VideoBlockProps): JSX.Element {
  const getUrl = (): string => {
    switch (provider) {
      case 'youtube':
        return `https://youtube.com/embed/${providerUid}`
      case 'vimeo':
        return `https://player.vimeo.com/video/${providerUid}`
      default:
        return ''
    }
  }
  return (
    <VideoBlockContainer>
      <IFrameWrapper>
        <IFrame title={title} src={getUrl()} frameBorder="0" allowFullScreen />
      </IFrameWrapper>
    </VideoBlockContainer>
  )
}
