import React from 'react'

import styled from 'styled-components'

import {VideoBannerBlockFields} from '@festi/common/api/datocms/types/VideoBannerBlockFields'

const VideoBanner = styled.div`
  max-height: 1000px;
  overflow: hidden;
`

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
`

const Video = styled.video`
  width: 100%;
  height: 150%;
`

export default function VideoBannerBlock({
  video: videoElement,
}: VideoBannerBlockFields): JSX.Element {
  const {video} = videoElement
  return (
    <VideoBanner>
      <VideoWrapper>
        <Video autoPlay={true} loop={true} muted playsInline>
          {/* TODO make it accept multiple media types */}
          <source src={video.mp4Url} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      </VideoWrapper>
    </VideoBanner>
  )
}
