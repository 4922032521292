import React from 'react'

import {Image} from 'react-datocms'
import styled from 'styled-components'

import {H3} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_AdviceCardRecord_image} from '@festi/common/api/datocms/types/Page'
import {Tag, TagWrapper} from './BlogCard'
import {media} from 'styled-bootstrap-grid'

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 12px;
`

const AdviceImage = styled(Image)`
  ${fluidRange('width', '148px', '168px')};
  border-radius: 12px;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  gap: 12px;
  ${fluidRange('margin-left', '12px', '30px')};

  ${media.md`
    display: block;
  `}
`

interface Props {
  title: string
  image: Page_page_content_GridSystemRecord_card_AdviceCardRecord_image | null
  tag: string[]
}

export default function AdviceCard({title, image}: Props): JSX.Element {
  return (
    <Container>
      <AdviceImage data={image.responsiveImage} objectFit="cover" />

      <Inner>
        <H3 withGutter>{title}</H3>
        <TagWrapper>
          <Tag>Not</Tag>
          <Tag>sure</Tag>
          <Tag>how</Tag>
          <Tag>To</Tag>
          <Tag>Implement</Tag>
        </TagWrapper>
      </Inner>
    </Container>
  )
}
