import React from 'react'

import useSWR from 'swr'

import {handleRestResponse, restApi} from '@festi/common/api/rest'

import NewProductCard from './NewProductCard'

interface Props {
  sku: string
}

export default function SignleProductCard({sku}: Props): JSX.Element {
  const {data: variant} = useSWR([sku, 'variant'], () =>
    handleRestResponse(restApi.productVariantsRetrieve(sku)),
  )

  if (variant === undefined) return <div>Loading...</div>

  return (
    <NewProductCard
      variant={variant}
      image={{
        url: variant.images?.[0]?.image.productGallery2x,
        alt: variant.images?.[0]?.alt,
      }}
    />
  )
}
