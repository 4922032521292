import React from 'react'

import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {
  Page_page_content_LocationListRecord_locations as Location,
  Page_page_content_LocationListRecord as LocationListType,
} from '@festi/common/api/datocms/types/Page'
import {H2, PLarge} from '@festi/common/components/typography'

import LocationCard from '../cards/LocationCard'
import {BlockWrapper} from '../layout'

const ListWrapper = styled.div``

export default function LocationCardList({
  title,
  description,
  locations,
  darkMode = false,
}: LocationListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <H2 withGutter darkMode={darkMode}>
          {title}
        </H2>
        <PLarge withGutter darkMode={darkMode}>
          {description}
        </PLarge>
        <ListWrapper>
          <Row>
            {locations?.map(({id, title, logo, location, link}: Location) => (
              <Col col={12} sm={6} md={3} key={id}>
                <LocationCard
                  title={title}
                  logo={logo?.responsiveImage.src}
                  link={link}
                  location={location}
                  darkMode={darkMode}
                />
              </Col>
            ))}
          </Row>
        </ListWrapper>
      </Container>
    </BlockWrapper>
  )
}
