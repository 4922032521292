import React from 'react'

import fromPairs from 'lodash/fromPairs'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import {Image} from 'react-datocms'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {
  CategorySliderFields,
  CategorySliderFields_categories_image_responsiveImage,
} from '@festi/common/api/datocms/types/CategorySliderFields'
import {Category} from '@festi/common/api/rest'
import {H1, H2, P} from '@festi/common/components/typography'
import {useCommonContext} from '@festi/common/contexts/common'

import {SlideInnerProps} from '../common/CustomSlider'
import {BlockWrapper} from '../layout'

const CustomSlider = dynamic(import('../common/CustomSlider'), {ssr: false})

const SliderWrapper = styled.div`
  ${media.md`
    overflow: hidden;
    padding-left: 2px;
  `}
`

const CategoryCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette('white')};
  border: 1px solid ${palette('ui20Solid')};
  padding: 20px;
  height: 100%;
  transition: border-color 0.15s;

  &:hover {
    box-shadow: ${theme('boxShadow.card')};
    border-color: ${palette('ui60')};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  flex-grow: 1;
`

interface CategorySlideProps extends SlideInnerProps {
  category: Category
  image: CategorySliderFields_categories_image_responsiveImage
}

export function CategorySlide({
  category,
  image,
  isHidden,
  onKeyDown,
  onMouseDown,
  onClick,
}: CategorySlideProps) {
  return (
    <Link
      href={`/voruflokkar/${category.slug}`}
      tabIndex={isHidden ? -1 : 0}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      <CategoryCard>
        <H2 size={4}>{category.name}</H2>
        <ImageWrapper>
          <Image data={image} lazyLoad={false} />
        </ImageWrapper>
      </CategoryCard>
    </Link>
  )
}

export default function CategorySlider({
  title,
  description,
  categories,
  darkMode,
}: CategorySliderFields): JSX.Element {
  const {categories: allCategories} = useCommonContext()
  const slugCategoryMap = fromPairs(allCategories.map((c) => [c.slug, c]))

  const slides = categories
    ?.map((datoCategory) => {
      const category = slugCategoryMap[datoCategory.slug]
      if (!category) {
        console.warn(
          `Category slide got an unknown slug (${datoCategory.slug}) from CMS`,
        )
        return null
      }
      return (
        <CategorySlide
          key={category.id}
          category={category}
          image={datoCategory.image?.responsiveImage}
        />
      )
    })
    .filter((x) => x)

  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <H1 size={2} withGutter darkMode={darkMode}>
          {title}
        </H1>

        <P withGutter darkMode={darkMode}>
          {description}
        </P>

        <SliderWrapper>
          <CustomSlider xl={6} lg={4} md={3} sm={2} darkMode={darkMode}>
            {slides}
          </CustomSlider>
        </SliderWrapper>
      </Container>
    </BlockWrapper>
  )
}
