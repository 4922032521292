import React from 'react'

import {Container} from 'styled-bootstrap-grid'

import {EmployeeListFields} from '@festi/common/api/datocms/types/EmployeeListFields'
import {H2, PLarge} from '@festi/common/components/typography'

import {BlockWrapper} from '../layout'
import EmployeeCardList from './EmployeeCardList'
import EmployeeSmallList from './EmployeeSmallList'

export default function EmployeeList({
  title,
  description,
  largeCards,
  employees,
}: EmployeeListFields) {
  const EmployeeList = largeCards ? EmployeeCardList : EmployeeSmallList
  return (
    <BlockWrapper>
      <Container>
        <H2 withGutter>{title}</H2>
        <PLarge withGutter>{description}</PLarge>
        <EmployeeList employees={employees} />
      </Container>
    </BlockWrapper>
  )
}
