import React from 'react'

import dynamic from 'next/dynamic'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {
  Page_page_content_LocationListRecord_locations as Location,
  Page_page_content_LocationListRecord as LocationListType,
} from '@festi/common/api/datocms/types/Page'
import {H1, PLarge} from '@festi/common/components/typography'

import {BlockWrapper} from '../layout'

const CustomSlider = dynamic(import('../common/CustomSlider'), {ssr: false})


const LocationCard = dynamic(import('../cards/LocationCard'), {ssr: false})


const SliderWrapper = styled.div`
  padding-top: 60px;
  overflow: hidden;
`

export default function LocationCardSlider({
  title,
  description,
  locations,
  darkMode = false,
}: LocationListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <H1 withGutter darkMode={darkMode}>
          {title}
        </H1>
        <PLarge withGutter darkMode={darkMode}>
          {description}
        </PLarge>
        <SliderWrapper>
          <CustomSlider xl={4} lg={3} md={2} sm={1} darkMode={darkMode}>
            {locations?.map(({id, title, logo, location}: Location) => (
              <LocationCard
                key={id}
                title={title}
                logo={logo?.responsiveImage.src}
                location={location}
                darkMode={darkMode}
              />
            ))}
          </CustomSlider>
        </SliderWrapper>
      </Container>
    </BlockWrapper>
  )
}
