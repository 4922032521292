import React from 'react'

import {Container} from 'styled-bootstrap-grid'

import {Page_page_content_ContentBlockRecord as ContentBlockType} from '@festi/common/api/datocms/types/Page'
import {DatoStructuredText} from '@festi/common/components/common'

import {BlockWrapper} from '../layout'

export default function ContentBlock({
  content,
  externalContent,
}: ContentBlockType): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <DatoStructuredText content={content} />
        <DatoStructuredText content={externalContent?.content} />
      </Container>
    </BlockWrapper>
  )
}
