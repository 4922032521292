import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import {Category} from '@festi/common/api/rest'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_CategoryRecord_image_responsiveImage} from '@festi/common/api/datocms/types/Page'

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${fluidRange('height', '128px', '200px')};
  width: 100%;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: ${palette('white')};
  overflow: hidden;

  &:hover {
    border-color: ${palette('ui20Solid')};
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  ${fluidRange('padding', '16px', '20px')};
`

const Title = styled.div`
  flex-grow: 1;
  width: 100%;
  font-family: 'ElkjopSans', sans-serif;
  text-align: center;
  ${fluidRange('font-size', '14px', '18px')};
  ${fluidRange('line-height', '20px', '24px')};
  font-weight: 500;
  color: ${palette('blue')};

  @media (max-width: 330px) {
    font-size: 13.5px;
  }
`

const CategoryImage = styled(Image)`
  align-self: flex-end;
  max-width: 220px;
  max-height: 101px;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-height: 90px;
  }

  @media (max-width: 480px) {
    max-height: 52px;
  }
`

interface Props {
  category: Category
  image: Page_page_content_GridSystemRecord_card_CategoryRecord_image_responsiveImage | null
  slug: string | null
}

export default function CategoryCard({
  category,
  image,
  slug,
}: Props): JSX.Element {
  return (
    <Container key={category?.id}>
      <Link href={`/voruflokkar/${slug}`}>
        <Inner>
          <Title>{category?.name}</Title>
          {image && (
            <CategoryImage
              data={image}
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </Inner>
      </Link>
    </Container>
  )
}
