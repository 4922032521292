import React from 'react'

import Link from 'next/link'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {H4, P} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_BlogCardRecord_image} from '@festi/common/api/datocms/types/Page'
import {styledTheme} from '@festi/common/themes'

const Container = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  ${fluidRange('height', '168px', '240px')};
`

const BlogImage = styled(Image)`
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const Inner = styled.div`
  width: 100%;
  ${fluidRange('padding', '24px', '32px')};
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`

export const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const Tag = styled.div`
  width: auto;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  color: ${palette('ui40Solid')};
  border: 1px solid ${palette('border')};
  border-radius: 4px;
`

const BlogLink = styled(Link)`
  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${palette('border')};
`

interface Props {
  title: string | null
  link: string | null
  description: string | null
  image: Page_page_content_GridSystemRecord_card_BlogCardRecord_image | null
}

export default function BlogCard({
  title,
  image,
  link,
  description,
}: Props): JSX.Element {
  return (
    <Container>
      <ImageWrapper>
        <BlogImage
          data={image.responsiveImage}
          layout="fill"
          objectFit="cover"
        />
      </ImageWrapper>

      <Inner>
        <H4 headline withGutter>
          {title}
        </H4>
        <P>{description}</P>

        <BottomWrapper>
          <TagWrapper>
            <Tag>Not</Tag>
            <Tag>sure</Tag>
            <Tag>how</Tag>
            <Tag>To</Tag>
            <Tag>Implement</Tag>
          </TagWrapper>

          {!!link && (
            <BlogLink href={link}>
              <ArrowRightSLineIcon color={styledTheme.palette.blue} />
            </BlogLink>
          )}
        </BottomWrapper>
      </Inner>
    </Container>
  )
}
