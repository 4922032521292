import React from 'react'

import {ProductCard} from 'src/components/cards'
import {BlockWrapper} from 'src/components/layout'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import useSWR from 'swr'

import {Page_page_content_ProductSliderRecord as ProductSliderType} from '@festi/common/api/datocms/types/Page'
import {restApi, handleRestResponse} from '@festi/common/api/rest'
import {DotsLoader} from '@festi/common/components/common'
import {onDutyFree} from '@festi/common/constants/channels'
import settings from '@festi/common/constants/settings'
import {useAuth} from '@festi/common/contexts'
import {useUserPrices} from '@festi/common/utils/rest'

import {TitleContent} from './ProductListTitle'
import {ProductListWrapperProps} from './interface'
import {ListItemWrapper} from './styles'

function ProductListWrapper({
  variants,
  title,
  userPrices,
  userPricesLoading,
}: ProductListWrapperProps): JSX.Element {
  const twoCards = variants?.results.length == 2
  const oneCard = variants?.results.length == 1
  const threeCards = variants?.results.length == 3

  return (
    <Row>
      {variants?.results.map((variant, index) => {
        return (
          <Col
            key={variant.id}
            col={12}
            sm={oneCard || twoCards ? 12 : threeCards ? 4 : 6}
            md={oneCard ? 12 : twoCards ? 6 : threeCards ? 4 : 3}
            lg={oneCard ? 12 : twoCards ? 6 : threeCards ? 4 : 3}
            alignSelf="stretch"
          >
            <ListItemWrapper>
              <ProductCard
                key={variant.id}
                variant={variant}
                origin={title}
                position={index + 1}
                userPrice={userPrices?.[variant.sku]}
                userPriceLoading={userPricesLoading}
                isFeatured={oneCard || twoCards}
              />
            </ListItemWrapper>
          </Col>
        )
      })}
    </Row>
  )
}

function UserProductListWrapper({
  variants,
  hideIfOutOfStock,
  skuList,
  title,
  darkMode,
  description,
}: ProductListWrapperProps): JSX.Element {
  const {data, loading} = useUserPrices(skuList)
  return (
    <ProductListWrapper
      variants={variants}
      hideIfOutOfStock={hideIfOutOfStock}
      title={title}
      darkMode={darkMode}
      description={description}
      userPrices={data}
      userPricesLoading={loading}
    ></ProductListWrapper>
  )
}

export default function ProductList({
  title,
  description,
  products,
  hideIfOutOfStock,
  darkMode = false,
}: ProductSliderType): JSX.Element {
  const skuList = products.map((p) => p.sku)
  const {user} = useAuth()
  const {data: variants, isValidating: variantLoading} = useSWR(
    ['variants', skuList],
    ([_, skus]) =>
      handleRestResponse(
        restApi.productVariantsList({
          sku: [skus.join(',')],
          channel: settings.channel,
        }),
      ),
    {revalidateOnFocus: false},
  )

  if (variantLoading) {
    return <DotsLoader />
  }

  return (
    <BlockWrapper>
      <Container>
        <TitleContent
          title={title}
          description={description}
          darkMode={darkMode}
        />
        {user && !onDutyFree ? (
          <UserProductListWrapper
            variants={variants}
            skuList={skuList}
            hideIfOutOfStock={hideIfOutOfStock}
            title={title}
            darkMode={darkMode}
            description={description}
          />
        ) : (
          <ProductListWrapper
            variants={variants}
            hideIfOutOfStock={hideIfOutOfStock}
            title={title}
            darkMode={darkMode}
            description={description}
          />
        )}
      </Container>
    </BlockWrapper>
  )
}
