import React, {useCallback, useEffect, useMemo, useState} from 'react'
import cn from 'classnames'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Container, media} from 'styled-bootstrap-grid'
import useEmblaCarousel from 'embla-carousel-react'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {fromPairs} from 'lodash'

import {H3} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import {useCommonContext} from '@festi/common/contexts/common'
import {CategoryCarouselFields} from '@festi/common/api/datocms/types/CategoryCarouselFields'

import {BlockWrapper} from 'src/components/layout'
import CategoryCard from 'src/components/cards/redesign/CategoryCard'
import {styledTheme} from '@festi/common/themes'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Carousel = styled.div`
  overflow: hidden;
  height: 100%;
`

const ButtonWrapper = styled.div`
  position: absolute;
  display: none;
  left: -16px;
  right: -16px;
  justify-content: space-between;
  align-items: center;

  ${media.sm`
    display: flex;
  `}
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fluidRange('width', '36px', '48px')};
  ${fluidRange('height', '36px', '48px')};
  padding: 0;
  border: 0;
  border-radius: 8px;
  background-color: ${palette('white')};
  box-shadow: 0px 12px 18px rgba(0, 14, 82, 0.04),
    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  cursor: ${styledTheme.cursor.pointer};
  z-index: 1;

  &:hover {
    background-color: ${palette('ui5Solid')};
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
`

const EmblaContainer = styled.div`
  display: flex;
  width: 100%;
  ${fluidRange('gap', '8px', '10px')};
`

const EmblaSlider = styled.div`
  flex: 0 0 46%;
  height: 100%;
  min-width: 0;

  ${media.sm`
    flex: 0 0 calc(100% / 3 - 7px);
  `}

  ${media.md`
    flex: 0 0 calc(100% / 3 - 9px);
  `}
`

const EmblaDoubleContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '10px')};

  ${media.md`
    flex-direction: row;
  `}
`

interface EmblaProps {
  children: React.ReactNode
}

function EmblaCarousel({children}: EmblaProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    containScroll: 'trimSnaps',
  })
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])

  return (
    <>
      <ButtonWrapper>
        <Button
          className={cn('embla__prev', {disabled: prevBtnDisabled})}
          onClick={() => emblaApi.canScrollPrev() && emblaApi.scrollPrev()}
        >
          <ArrowLeftSLineIcon />
        </Button>

        <Button
          className={cn('embla__next', {disabled: nextBtnDisabled})}
          onClick={() => emblaApi.scrollNext()}
        >
          <ArrowRightSLineIcon />
        </Button>
      </ButtonWrapper>

      <Carousel className="embla" ref={emblaRef}>
        <EmblaContainer>{children}</EmblaContainer>
      </Carousel>
    </>
  )
}

export default function CategoryCarousel({
  title,
  categories,
}: CategoryCarouselFields): JSX.Element {
  const {categories: allCategories} = useCommonContext()
  const slugCategoryMap = fromPairs(allCategories.map((c) => [c.slug, c]))

  const slides = useMemo(() => {
    const doubleSlides = []

    for (let i = 0; i < categories.length; i += 2) {
      const item = categories[i]
      const nextItem = categories[i + 1]

      const category = slugCategoryMap[item.slug]
      const nextCategory = nextItem ? slugCategoryMap[nextItem.slug] : null

      if (!category) {
        console.warn(
          `Category slide got an unknown slug (${item.slug}) from CMS`,
        )
        continue
      }

      doubleSlides.push(
        <EmblaSlider key={category.id}>
          <EmblaDoubleContainer>
            <CategoryCard
              slug={category?.slug}
              category={category}
              image={item.image?.responsiveImage}
            />
            {nextItem && nextCategory && (
              <CategoryCard
                slug={nextCategory?.slug}
                category={nextCategory}
                image={nextItem.image?.responsiveImage}
              />
            )}
          </EmblaDoubleContainer>
        </EmblaSlider>,
      )
    }
    return doubleSlides
  }, [categories, slugCategoryMap])

  return (
    <BlockWrapper>
      <Container>
        <H3>{title.toString()}</H3>
        <Wrapper>
          <CarouselContainer className="embla_viewport">
            <EmblaCarousel>{slides}</EmblaCarousel>
          </CarouselContainer>
        </Wrapper>
      </Container>
    </BlockWrapper>
  )
}
