import React from 'react'

import {Container} from 'styled-bootstrap-grid'

import {Page_page_content_VideoBlockRecord as VideoBlockType} from '@festi/common/api/datocms/types/Page'

import Video from '../common/Video'
import {BlockWrapper} from '../layout'

export default function VideoBlock({video}: VideoBlockType): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <Video {...video} />
      </Container>
    </BlockWrapper>
  )
}
