import React from 'react'

import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {prop, ifProp} from 'styled-tools'

import {
  Page_page_content_CardImageBlockRecord as CardImageType,
  Page_page_content_CardImageBlockRecord_image as ImageType,
  Page_page_content_CardImageBlockRecord_content as ContentType,
} from '@festi/common/api/datocms/types/Page'
import {fluidRange} from '@festi/common/utils/styles'

import {TextCard} from '../cards'
import {BlockWrapper} from '../layout'

interface ImageProps {
  imgUrl: string
  fullWidth?: boolean
  mirrored?: boolean
  smHide?: boolean
}

interface CardImageContainerProps {
  fullWidth?: boolean
}

interface CardImageProps {
  title: string
  content: ContentType
  image: ImageType
  mirrored: boolean
}

const Image = styled.div<ImageProps>`
  ${fluidRange('min-height', '380px', '500px')}

  &:before {
    content: '';
    position: absolute;
    right: -8px;
    left: -8px;
    bottom: 0;
    top: 0;
    background-image: url(${prop('imgUrl')});
    background-position: center;
    background-size: cover;

    ${(props) =>
      !props.fullWidth &&
      media.sm`
      left: ${ifProp({mirrored: true}, '0px', '-100px')};
      right: ${ifProp({mirrored: true}, '-100px', '0px')};
    `}

    ${media.sm`
      display: ${ifProp({smHide: true}, 'none', 'block')};
    `}
  }
`

const CardImageContainer = styled.div<CardImageContainerProps>`
  max-width: 1344px;
  margin: auto;
`

const TextCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -100px;

  ${media.sm`
    height: 100%;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  `}
`

const ImageFullWidth = styled.div`
  display: none;
  ${media.sm`
    display: block;
  `};
`

const CardImageWrapper = styled.div`
  position: relative;
  ${fluidRange('min-height', '380px', '500px')};
`

function CardImageFullWidth({
  title,
  content,
  image,
  mirrored,
}: CardImageProps): JSX.Element {
  return (
    <>
      <ImageFullWidth>
        <Image imgUrl={image?.fullWidth.src} fullWidth mirrored={mirrored} />
      </ImageFullWidth>
      <Col col={12} sm={6}>
        <Image imgUrl={image?.regular.src} mirrored={mirrored} smHide />
      </Col>
      <Col col={12} sm={6} order="last" smOrder={mirrored ? 'last' : 'first'}>
        <TextCardWrapper>
          <TextCard title={title} content={content} />
        </TextCardWrapper>
      </Col>
    </>
  )
}

function CardImageColumns({
  title,
  content,
  image,
  mirrored,
}: CardImageProps): JSX.Element {
  return (
    <>
      <Col col={12} sm={6} order="last" smOrder={mirrored ? 'last' : 'first'}>
        <TextCardWrapper>
          <TextCard title={title} content={content} />
        </TextCardWrapper>
      </Col>
      <Col col={12} sm={6}>
        <Image imgUrl={image?.regular.src} mirrored={mirrored} />
      </Col>
    </>
  )
}

export default function CardImageBlock({
  title,
  content,
  image,
  mirrored = false,
  fullWidthImage = false,
}: CardImageType): JSX.Element {
  const ImageComponent = fullWidthImage ? CardImageFullWidth : CardImageColumns
  return (
    <BlockWrapper compact={fullWidthImage ? true : false}>
      <Container>
        <CardImageWrapper>
          <CardImageContainer fullWidth={fullWidthImage}>
            <Row>
              <ImageComponent
                title={title}
                content={content}
                image={image}
                mirrored={mirrored}
              />
            </Row>
          </CardImageContainer>
        </CardImageWrapper>
      </Container>
    </BlockWrapper>
  )
}
