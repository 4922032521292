import React from 'react'

import {Page_page_content_GridSystemRecord} from '@festi/common/api/datocms/types/Page'

import Grid, {GridAreaNames} from 'src/components/layout/Grid'
import {BlockWrapper} from 'src/components/layout'

export default function GridBlock({
  card,
  heading,
  gridSelect,
}: Page_page_content_GridSystemRecord): JSX.Element {
  return (
    <BlockWrapper>
      <Grid
        cards={card}
        heading={heading}
        gridSelect={gridSelect as GridAreaNames}
      />
    </BlockWrapper>
  )
}
