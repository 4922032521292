import React from 'react'

import {Page_page_content_LocationListRecord as LocationListTyoe} from '@festi/common/api/datocms/types/Page'

import LocationCardList from './LocationCardList'
import LocationCardSlider from './LocationCardSlider'

export default function LocationList(props: LocationListTyoe): JSX.Element {
  if (props.showInSlider) {
    return <LocationCardSlider {...props} />
  } else {
    return <LocationCardList {...props} />
  }
}
