import React from 'react'

import {Col, Row} from 'styled-bootstrap-grid'

import {EmployeeListFields_employees as Employee} from '@festi/common/api/datocms/types/EmployeeListFields'

import EmployeeCard from '../cards/EmployeeCard'

interface Props {
  employees: Employee[]
}

export default function EmployeeCardList({employees}: Props) {
  return (
    <Row>
      {employees.map((employee: Employee) => (
        <Col key={employee.id} col={12} md={6}>
          <EmployeeCard {...employee} />
        </Col>
      ))}
    </Row>
  )
}
