import React from 'react'

import {Image} from 'react-datocms'
import {Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {Page_page_content_ImageBlockRecord as ImageBlockType} from '@festi/common/api/datocms/types/Page'
import {H1, PLarge} from '@festi/common/components/typography'

interface LinkProps {
  children: React.ReactNode
}

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default function ImageBlock({
  title,
  description,
  image,
  link,
}: ImageBlockType): JSX.Element {
  const ImageLink = ({children}: LinkProps) =>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    link ? <a href={link}>{children}</a> : <>{children}</>

  return (
    <Container>
      <H1 withGutter>{title}</H1>
      <PLarge withGutter>{description}</PLarge>
      <ImageWrapper>
        <ImageLink>
          <Image data={image.responsiveImage} />
        </ImageLink>
      </ImageWrapper>
    </Container>
  )
}
