import React from 'react'

import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {
  Page_page_content_InfoCardListRecord_infoCards as InfoCardType,
  Page_page_content_InfoCardListRecord as InfoCardListType,
} from '@festi/common/api/datocms/types/Page'
import {H2, PLarge} from '@festi/common/components/typography'

import {InfoCard} from '../common'
import {BlockWrapper} from '../layout'

const ListWrapper = styled.div``

export default function InfoCardList({
  title,
  description,
  infoCards,
  small = false,
  darkMode = false,
  border = false,
}: InfoCardListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <Row alignItems="center">
          <Col col={12} md={6}>
            <H2 withGutter darkMode={darkMode}>
              {title}
            </H2>
          </Col>
          <Col col={12} md={6}>
            <PLarge withGutter darkMode={darkMode}>
              {description}
            </PLarge>
          </Col>
        </Row>
        <ListWrapper>
          <Row>
            {infoCards?.map(
              ({id, title, icon, description, link}: InfoCardType) => (
                <Col col={12} sm={6} md={small ? 12 / 5 : 3} key={id}>
                  <InfoCard
                    title={title}
                    icon={icon}
                    description={description}
                    link={link}
                    border={border}
                    darkMode={darkMode}
                    small={small}
                  />
                </Col>
              ),
            )}
          </Row>
        </ListWrapper>
      </Container>
    </BlockWrapper>
  )
}
