import React from 'react'

import cn from 'classnames'
import dynamic from 'next/dynamic'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import useSWR from 'swr'

import {Page_page_content_ProductSliderRecord as ProductSliderType} from '@festi/common/api/datocms/types/Page'
import {restApi, handleRestResponse} from '@festi/common/api/rest'
import {PaginatedVariantListList} from '@festi/common/api/rest/data-contracts'
import {DotsLoader} from '@festi/common/components/common'
import {H1, P} from '@festi/common/components/typography'
import settings from '@festi/common/constants/settings'
import {onDutyFree} from '@festi/common/constants/channels'
import {UserPriceRecord, useUserPrices} from '@festi/common/utils/rest'

import {ProductCard} from '../../cards'
import {CardWrapper} from '../../cards/product'
import {MiniSlider} from '../../common/CustomSlider'
import {darkModeCSS, spacing} from '../../layout/BlockWrapper'

const CustomSlider = dynamic(import('../../common/CustomSlider'), {ssr: false})

const SliderBlockWrapper = styled.div`
  position: relative;

  &.darkMode {
    ${darkModeCSS}
  }

  ${MiniSlider} {
    ${CardWrapper}:hover {
      // Send sliding indicator back because of ProductCard overflow
      z-index: -1;
    }
  }
`

const SliderWrapper = styled.div`
  ${spacing(10, 30)}

  ${media.md`
    overflow: hidden;
  `}
`

const TitleBlock = styled.div`
  max-width: 800px;
`

interface TitleContentProps {
  title: string
  darkMode: boolean
  description: string
}

function TitleContent({
  title,
  description,
  darkMode,
}: TitleContentProps): JSX.Element {
  return (
    <TitleBlock>
      <H1 size={2} withGutter darkMode={darkMode}>
        {title}
      </H1>
      {!!description && (
        <P withGutter darkMode={darkMode}>
          {description}
        </P>
      )}
    </TitleBlock>
  )
}

interface ProductSliderWrapperProps {
  title: string
  skuList: string[]
  variants: PaginatedVariantListList
  darkMode: boolean
  description: string
  userPrices?: UserPriceRecord
  userPricesLoading?: boolean
  hideIfOutOfStock: boolean
}

function ProductSliderWrapper({
  title,
  skuList,
  variants,
  description,
  hideIfOutOfStock,
  darkMode,
}: ProductSliderWrapperProps) {
  const {data: userPrices, loading: userPricesLoading} = useUserPrices(skuList)

  const inStockVariants = variants?.results?.filter((variant) => {
    const inOfStock = onDutyFree
      ? variant.isInStockDutyfree
      : variant.isInStockWeb
    return !hideIfOutOfStock || inOfStock
  })

  return (
    <SliderWrapper>
      <CustomSlider
        xl={4}
        lg={3}
        md={2}
        sm={1.1}
        darkMode={darkMode}
        title={
          <TitleContent
            title={title}
            description={description}
            darkMode={darkMode}
          />
        }
      >
        {inStockVariants?.map((variant, index) => (
          <ProductCard
            key={variant.sku}
            origin={title}
            variant={variant}
            position={index + 1}
            userPrice={userPrices?.[variant.sku]}
            userPriceLoading={userPricesLoading}
          />
        ))}
      </CustomSlider>
    </SliderWrapper>
  )
}

export default function ProductSlider({
  title,
  products,
  description,
  hideIfOutOfStock,
  darkMode = false,
}: ProductSliderType): JSX.Element {
  const skuList = products.map((p) => p.sku)

  const {data: variants, isValidating: variantLoading} = useSWR(
    ['variants', skuList],
    ([_, skus]) =>
      handleRestResponse(
        restApi.productVariantsList({
          sku: [skus.join(',')],
          channel: settings.channel,
        }),
      ),
    {revalidateOnFocus: false},
  )

  if (variantLoading) {
    return <DotsLoader />
  }

  return (
    <SliderBlockWrapper className={cn({darkMode})}>
      <Container>
        <ProductSliderWrapper
          variants={variants}
          hideIfOutOfStock={hideIfOutOfStock}
          title={title}
          skuList={skuList}
          darkMode={darkMode}
          description={description}
        />
      </Container>
    </SliderBlockWrapper>
  )
}
