import React from 'react'

import Link from 'next/link'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {InfoBulletListFields} from '@festi/common/api/datocms/types/InfoBulletListFields'
import {Page_page_content_InfoBulletListRecord_infoBullets as InfoBullet} from '@festi/common/api/datocms/types/Page'
import {DynamicIcon} from '@festi/common/components/common'
import {H1, P} from '@festi/common/components/typography'

import {BlockWrapper} from '../layout'

const Bullet = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 300px;
  margin: auto;
  margin-bottom: 10px;
`

const BulletText = styled.div`
  margin-left: 16px;

  ${H1} {
    margin-bottom: 5px;
  }

  ${P} {
    line-height: 1.2;
  }
`

function InfoBulletItem({
  icon,
  title,
  description,
  link,
}: InfoBullet): JSX.Element {
  const bullet = (
    <Bullet>
      <DynamicIcon icon={icon} size={50} />
      <BulletText>
        <H1 size={5}>{title}</H1>
        <P>{description}</P>
      </BulletText>
    </Bullet>
  )

  if (link) {
    return <Link href={link}>{bullet}</Link>
  }

  return bullet
}

export default function InfoBulletList({
  infoBullets,
}: InfoBulletListFields): JSX.Element {
  return (
    <BlockWrapper>
      <Container>
        <Row>
          {infoBullets?.map((bullet: InfoBullet) => (
            <Col sm={6} md={3} key={bullet.id}>
              <InfoBulletItem {...bullet} />
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
